import { InjectionToken, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, Routes } from '@angular/router';
import { AdminLayoutComponent } from './components/layouts/admin-layout/admin-layout.component';
import { MyLearningComponent } from './ui/user/my-learning/my-learning.component';
import { ContentLibraryComponent } from './ui/user/content-library/content-library.component';
import { InstructorsComponent } from './ui/user/instructors/instructors.component';
import { MyCoursesComponent } from './ui/user/my-courses/my-courses.component';
import { UserLayoutComponent } from './components/layouts/user-layout/user-layout.component';
import { CertificatesComponent } from './ui/user/badges-certs/certificates/certificates.component';
import { CourseCatalogComponent } from './ui/user/course-catalog/course-catalog.component';
import { BadgesCertsComponent } from './ui/user/badges-certs/badges-certs.component';
import { InstructorLayoutComponent } from './components/layouts/instructor-layout/instructor-layout.component';
import { AssignmentsManagerLayoutComponent } from './ui/instructor/classroom-manager/assignments-manager-layout/assignments-manager-layout.component';
import { StudentManagerComponent } from './ui/instructor/classroom-manager/student-manager-layout/student-manager/student-manager.component';
import { InstructorDashboardComponent } from './ui/instructor/instructor-dashboard/instructor-dashboard.component';
import { NotificationsAdminManagerComponent } from './ui/admin/notifications-admin-manager/notifications-admin-manager.component';
import { QuizManagerComponent } from './ui/admin/quiz-manager/quiz-manager.component';
import { TranscriptsManagerComponent } from './ui/instructor/transcripts-manager/transcripts-manager.component';
import { AdminDashboardComponent } from './ui/admin/admin-dashboard/admin-dashboard.component';
import { GamificationManagerComponent } from './ui/admin/gamification-manager/gamification-manager.component';
import { UserManagerComponent } from './ui/admin/user-manager/user-manager.component';
import { CoursesComponent } from './ui/admin/course-manager/courses/courses.component';
import { AddCourseCategoryComponent } from './ui/admin/category-manager/course-categories/add-course-category/add-course-category.component';
import { ManageCourseCategoryComponent } from './ui/admin/category-manager/course-categories/manage-course-category/manage-course-category.component';
import { CategoryManagerComponent } from './ui/admin/category-manager/category-manager.component';
import { AircraftComponent } from './ui/admin/aircraft-manager/aircraft/aircraft.component';
import { AddAircraftComponent } from './ui/admin/aircraft-manager/add-aircraft/add-aircraft.component';
import { ManageAircraftComponent } from './ui/admin/aircraft-manager/manage-aircraft/manage-aircraft.component';
import { ContentManagerOverviewComponent } from './ui/admin/content-manager/content-manager-overview/content-manager-overview.component';
import { AddContentComponent } from './ui/admin/content-manager/add-content/add-content.component';
import { ManageContentComponent } from './ui/admin/content-manager/manage-content/manage-content.component';
import { AddBadgeComponent } from './ui/admin/badges-manager/add-badge/add-badge.component';
import { BadgesManagerOverviewComponent } from './ui/admin/badges-manager/badges-manager-overview/badges-manager-overview.component';
import { TaskManagerComponent } from './ui/admin/task-manager/task-manager.component';
import { TaskManagerOverviewComponent } from './ui/admin/task-manager/task-manager-overview/task-manager-overview.component';
import { AddTaskComponent } from './ui/admin/task-manager/add-task/add-task.component';
import { ManageTaskComponent } from './ui/admin/task-manager/manage-task/manage-task.component';
import { BadgesComponent } from './ui/user/badges-certs/badges/badges.component';
import { CourseDashboardComponent } from './ui/admin/course-manager/course-dashboard/course-dashboard.component';
import { ClassroomDashboardComponent } from './ui/instructor/classroom-manager/classroom-dashboard/classroom-dashboard.component';
import { AvionicsComponent } from './ui/admin/avionics-manager/avionics/avionics.component';
import { AddAvionicsComponent } from './ui/admin/avionics-manager/add-avionics/add-avionics.component';
import { ManageAvionicsComponent } from './ui/admin/avionics-manager/manage-avionics/manage-avionics.component';
import { CourseCategoriesOverviewComponent } from './ui/admin/category-manager/course-categories/course-categories-overview/course-categories-overview.component';
import { AddTaskCategoryComponent } from './ui/admin/category-manager/task-categories/add-task-category/add-task-category.component';
import { ManageTaskCategoryComponent } from './ui/admin/category-manager/task-categories/manage-task-category/manage-task-category.component';
import { TaskCategoriesOverviewComponent } from './ui/admin/category-manager/task-categories/task-categories-overview/task-categories-overview.component';
import { HelpComponent } from './ui/user/help/help.component';
import { StudentAssignmentsComponent } from './ui/instructor/classroom-manager/student-manager-layout/student-assignments/student-assignments.component';
import { StudentManagerLayoutComponent } from './ui/instructor/classroom-manager/student-manager-layout/student-manager-layout.component';
import { StudentAssessmentsLayoutComponent } from './ui/instructor/classroom-manager/student-manager-layout/student-assessments-layout/student-assessments-layout.component';
import { StudentAssessmentsComponent } from './ui/instructor/classroom-manager/student-manager-layout/student-assessments-layout/student-assessments/student-assessments.component';
import { StudentAssessmentsEntryComponent } from './ui/instructor/classroom-manager/student-manager-layout/student-assessments-layout/student-assessments-entry/student-assessments-entry.component';
import { StudentAssessmentsViewComponent } from './ui/instructor/classroom-manager/student-manager-layout/student-assessments-layout/student-assessments-view/student-assessments-view.component';
import { AssignmentsManagerAddComponent } from './ui/instructor/classroom-manager/assignments-manager-layout/assignments-manager-add/assignments-manager-add.component';
import { AssignmentsManagerManageComponent } from './ui/instructor/classroom-manager/assignments-manager-layout/assignments-manager-manage/assignments-manager-manage.component';
import { AssignmentsManagerOverviewComponent } from './ui/instructor/classroom-manager/assignments-manager-layout/assignments-manager-overview/assignments-manager-overview.component';
import { BookshelfManagerOverviewComponent } from './ui/instructor/bookshelf-manager/bookshelf-manager-overview/bookshelf-manager-overview.component';
import { BookshelfAddContentComponent } from './ui/instructor/bookshelf-manager/bookshelf-add-content/bookshelf-add-content.component';
import { BookshelfManageContentComponent } from './ui/instructor/bookshelf-manager/bookshelf-manage-content/bookshelf-manage-content.component';
import { ManageBadgeComponent } from './ui/admin/badges-manager/manage-badge/manage-badge.component';
import { CertificateManagerOverviewComponent } from './ui/admin/certificates-manager/certificate-manager-overview/certificate-manager-overview.component';
import { AddCertificateComponent } from './ui/admin/certificates-manager/add-certificate/add-certificate.component';
import { ManageCertificateComponent } from './ui/admin/certificates-manager/manage-certificate/manage-certificate.component';
import { UserManagerOverviewComponent } from './ui/admin/user-manager/user-manager-overview/user-manager-overview.component';
import { InstructorManagerComponent } from './ui/admin/user-manager/instructor-manager/instructor-manager.component';
import { FaqCategoryOverviewComponent } from './ui/admin/faq-categories-manager/faq-category-overview/faq-category-overview.component';
import { AddFaqCategoryComponent } from './ui/admin/faq-categories-manager/add-faq-category/add-faq-category.component';
import { ManageFaqCategoryComponent } from './ui/admin/faq-categories-manager/manage-faq-category/manage-faq-category.component';
import { FaqOverviewComponent } from './ui/admin/faq-manager/faq-overview/faq-overview.component';
import { AddFaqComponent } from './ui/admin/faq-manager/add-faq/add-faq.component';
import { ManageFaqComponent } from './ui/admin/faq-manager/manage-faq/manage-faq.component';
import { ErrorPageComponent } from './ui/user/error-page/error-page.component';
import { AdminGuard } from './guards/admin.guard';
import { InstructorGuard } from './guards/instructor.guard';
import { UserGuard } from './guards/user.guard';
import { AdminReportsManagerComponent } from './ui/admin/admin-reports-manager/admin-reports-manager.component';
import { CommentModeratingComponent } from './ui/admin/comment-moderating/comment-moderating.component';
import { DeferReasonOverviewComponent } from './ui/admin/task-manager/defer-reason-overview/defer-reason-overview.component';
import { ManageDeferReasonComponent } from './ui/admin/task-manager/manage-defer-reason/manage-defer-reason.component';
import { AddDeferReasonComponent } from './ui/admin/task-manager/add-defer-reason/add-defer-reason.component';
import { UserShoppingCartComponent } from './ui/user/checkout/user-shopping-cart/user-shopping-cart.component';
import { CheckoutComponent } from './ui/user/checkout/checkout/checkout.component';
import { ReceiptComponent } from './ui/user/checkout/receipt/receipt.component';
import { UserOrderHistoryComponent } from './ui/admin/user-order-history/user-order-history.component';
import { UserOrderComponent } from './ui/admin/user-order-history/user-order/user-order.component';
import { RecentPurchasesComponent } from './ui/user/checkout/recent-purchases/recent-purchases.component';
import { PurchaseHistoryComponent } from './ui/user/purchase-history/purchase-history.component';
import { TranscriptCourseDetailComponent } from './ui/instructor/transcripts-manager/transcript-course-detail/transcript-course-detail.component';
import { StudentUserCoursesComponent } from './ui/instructor/classroom-manager/student-manager-layout/student-user-courses/student-user-courses.component';
import { StudentAssessmentsTaskDetailComponent } from './ui/instructor/classroom-manager/student-manager-layout/student-assessments-layout/student-assessments-entry/student-assessments-task-detail/student-assessments-task-detail.component';
import { LessonOverviewComponent } from './ui/admin/lesson-manager/lesson-overview/lesson-overview.component';
import { LessonEditorComponent } from './ui/admin/lesson-manager/lesson-editor/lesson-editor.component';
import { RoutePassthroughComponent } from '@app/components/shared/route-passthrough/route-passthrough.component';
import { CourseEditorComponent } from './ui/admin/course-manager/courses/course-editor/course-editor.component';
import { ConfigurationManagerComponent } from './ui/admin/configuration-manager/configuration-manager.component';
import { TranscriptEditCertComponent } from './ui/instructor/transcripts-manager/transcript-edit-cert/transcript-edit-cert.component';
import { AdminReportCourseDetailComponent } from './ui/admin/admin-reports-manager/admin-reports-dialog/admin-report-course-detail/admin-report-course-detail.component';
import { EnrollmentHistoryComponent } from './ui/instructor/enrollment-history/enrollment-history.component';
import { TrainingPartnersComponent } from './ui/training-partners/training-partners.component';
import { TrainingCentersGuard } from './training-centers.guard';
import { CtcLayoutComponent } from './components/layouts/ctc-layout/ctc-layout.component';
import { CtcDashboardComponent } from './ui/ctc/ctc-dashboard/ctc-dashboard.component';
import { CTCGuard } from './guards/ctc.guard';
import { NoOpComponent } from '@app/no-op/no-op.component';
import { RetiredRouteRedirectComponent } from './retired-route-redirect/retired-route-redirect.component';
import { AuthenticatedComponent } from './authenticated/authenticated.component';
import { SignOutComponent } from './sign-out/sign-out.component';
import { AuthenticateComponent } from './authenticate/authenticate.component';

const getResolvedUrl = (route: ActivatedRouteSnapshot): string => {
  const params = Object.keys(route.params)
    .map(k => `${k}=${route.params[k]}`)
    .join('&');
  const path = route.pathFromRoot.map(v => v.url.map(segment => segment.path).join('/')).join('/');

  if (path === '/home') {
    return 'recent-activity';
  }

  return params.length > 0 ? `${path}?${params}` : path;
};

const coursesUrlProvider = new InjectionToken('coursesUrlRedirectResolver');

const appRoutes: Routes = [
  {
    matcher: url => {
      if (['courses', 'recent-activity', 'home', 'redeem', 'ctc-admin', 'settings'].includes(url[0].path)) {
        return { consumed: url };
      }

      return null;
    },
    resolve: {
      url: coursesUrlProvider,
    },
    component: NoOpComponent,
  },
  {
    path: 'training-partners',
    redirectTo: 'authorized-training-network',
    pathMatch: 'full'
  },
  { path: 'sign-in', component: AuthenticateComponent },
  { path: 'authenticated', component: AuthenticatedComponent },
  { path: 'sign-out', component: SignOutComponent },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'admin',
        children: [
          {
            path: 'dashboard',
            component: AdminDashboardComponent,
            data: {
              title: 'Dashboard',
              breadcrumb: 'DASHBOARD',
              path: 'admin/dashboard',
            },
          },
          {
            path: 'comment-moderating',
            component: CommentModeratingComponent,
            data: {
              title: 'Comment Moderating',
              breadcrumb: 'MODERATING',
              path: 'admin/dashboard',
            },
          },
          {
            path: 'course-dashboard',
            component: CourseDashboardComponent,
            data: {
              title: 'Course Dashboard',
              breadcrumb: 'COURSE DASHBOARD',
              path: 'admin/course-dashboard',
            },
          },
          {
            path: 'user-manager',
            component: UserManagerComponent,
            children: [
              {
                path: 'instructors',
                component: InstructorManagerComponent,
                data: {
                  title: 'Instructors',
                  breadcrumb: 'INSTRUCTORS',
                  path: 'admin/user-manager',
                },
              },
              {
                path: 'users',
                component: UserManagerOverviewComponent,
                data: {
                  title: 'Users',
                  breadcrumb: 'USERS',
                  path: 'admin/user-manager',
                },
              },
            ],
            data: { title: 'Users', breadcrumb: 'USERS', path: 'admin' },
          },
          {
            path: 'configuration',
            component: ConfigurationManagerComponent,
            data: {
              title: 'LMS Configuration',
              breadcrumb: 'CONFIGURATION',
              path: 'admin/configuration',
            },
          },
          {
            path: 'user-order-history',
            component: UserOrderHistoryComponent,
            data: {
              title: 'Order History',
              breadcrumb: 'ORDER HISTORY',
              path: 'admin',
            },
          },
          {
            path: 'user-order-history/user-order/:id',
            component: UserOrderComponent,
            data: {
              title: 'User Orders',
              breadcrumb: 'USER ORDERS',
              path: 'admin',
            },
          },
          {
            path: 'user-order-history/:id',
            component: UserOrderHistoryComponent,
            data: {
              title: 'Order History',
              breadcrumb: 'ORDER HISTORY',
              path: 'admin',
            },
          },
          {
            path: 'gamification-manager',
            component: GamificationManagerComponent,
          },
          {
            path: 'assignments-manager-layout',
            component: AssignmentsManagerLayoutComponent,
          },
          { path: 'student-manager', component: StudentManagerComponent },
          {
            path: 'notifications',
            component: NotificationsAdminManagerComponent,
            data: {
              title: 'Notifications',
              breadcrumb: 'NOTIFICATIONS',
              path: 'admin/notifications',
            },
          },
          { path: 'quiz-manager', component: QuizManagerComponent },
          {
            path: 'transcripts-manager',
            component: TranscriptsManagerComponent,
          },
          {
            path: 'transcripts/:id',
            component: TranscriptsManagerComponent,
            data: {
              title: 'Transcripts',
              breadcrumb: 'TRANSCRIPTS',
              path: 'admin/transcripts/{{id}}',
              noRootPath: true,
            },
          },
          {
            path: 'transcripts/:user_id/course-detail/:id',
            component: TranscriptCourseDetailComponent,
            data: {
              title: 'Course Detail',
              breadcrumb: 'COURSE DETAIL',
              path: 'admin/transcripts/{{user_id}}/course-detail/{{id}}',
              noRootPath: true,
            },
          },
          {
            path: 'reports',
            component: AdminReportsManagerComponent,
            data: {
              title: 'Reports',
              breadcrumb: 'REPORTS',
              path: 'admin/reports',
            },
          },
          {
            path: 'reports/:user_id/course-detail/:id',
            component: AdminReportCourseDetailComponent,
            data: {
              title: 'Course Detail',
              breadcrumb: 'COURSE DETAIL',
              path: 'admin/reports/{{user_id}}/course-detail/{{id}}',
              noRootPath: true,
            },
          },
          {
            path: 'transcripts/:user_id/edit-cert/:user_certificate_id',
            component: TranscriptEditCertComponent,
            data: {
              title: 'Edit Certificate',
              breadcrumb: 'EDIT CERTIFICATE',
              path: 'admin/transcripts/{{user_id}}/edit-cert/{{user_certificate_id}}',
              noRootPath: true,
            },
          },
          {
            path: 'badges',
            component: RoutePassthroughComponent,
            children: [
              {
                path: '',
                component: BadgesManagerOverviewComponent,
                data: {
                  title: 'Add Badge',
                  breadcrumb: 'ADD BADGE',
                  path: 'admin/badges',
                },
              },
              {
                path: 'add',
                component: AddBadgeComponent,
                data: {
                  title: 'Add Badge',
                  breadcrumb: 'ADD BADGE',
                  path: 'admin/badges',
                },
              },
              {
                path: 'manage/:id',
                component: ManageBadgeComponent,
                data: {
                  title: 'Manage Badge',
                  breadcrumb: 'MANAGE BADGE',
                  path: 'admin/badges',
                },
              },
            ],
            data: { title: 'Badges', breadcrumb: 'BADGES', path: 'admin' },
          },
          {
            path: 'certificates',
            component: RoutePassthroughComponent,
            children: [
              {
                path: '',
                component: CertificateManagerOverviewComponent,
                data: {
                  title: 'Add Certificate',
                  breadcrumb: 'ADD CERTIFICATE',
                  path: 'admin/certificates',
                },
              },
              {
                path: 'add',
                component: AddCertificateComponent,
                data: {
                  title: 'Add Certificate',
                  breadcrumb: 'ADD CERTIFICATE',
                  path: 'admin/certificates',
                },
              },
              {
                path: 'manage/:id',
                component: ManageCertificateComponent,
                data: {
                  title: 'Manage Certificate',
                  breadcrumb: 'MANAGE CERTIFICATE',
                  path: 'admin/certificates',
                },
              },
            ],
            data: {
              title: 'Certificates',
              breadcrumb: 'CERTIFICATES',
              path: 'admin',
            },
          },
          {
            path: 'faq-category',
            component: RoutePassthroughComponent,
            children: [
              {
                path: '',
                component: FaqCategoryOverviewComponent,
                data: {
                  title: 'Faq Category',
                  breadcrumb: 'FAQ CATEGORY',
                  path: 'admin/faq-category',
                },
              },
              {
                path: 'add',
                component: AddFaqCategoryComponent,
                data: {
                  title: 'Add Faq Category',
                  breadcrumb: 'ADD FAQ CATEGORY',
                  path: 'admin/faq-category',
                },
              },
              {
                path: 'manage/:id',
                component: ManageFaqCategoryComponent,
                data: {
                  title: 'Manage Faq Category',
                  breadcrumb: 'MANAGE FAQ CATEGORY',
                  path: 'admin/faq-category',
                },
              },
            ],
            data: {
              title: 'Faq Category',
              breadcrumb: 'FAQ CATEGORIES',
              path: 'admin',
            },
          },
          {
            path: 'faq',
            component: RoutePassthroughComponent,
            children: [
              {
                path: '',
                component: FaqOverviewComponent,
                data: { title: 'Faq', breadcrumb: 'FAQ', path: 'admin/faq' },
              },
              {
                path: 'add',
                component: AddFaqComponent,
                data: {
                  title: 'Add Faq',
                  breadcrumb: 'ADD FAQ',
                  path: 'admin/faq',
                },
              },
              {
                path: 'manage/:id',
                component: ManageFaqComponent,
                data: {
                  title: 'Manage Faq',
                  breadcrumb: 'MANAGE FAQ',
                  path: 'admin/faq',
                },
              },
            ],
            data: { title: 'Faq', breadcrumb: 'FAQ', path: 'admin' },
          },
          {
            path: 'lessons',
            component: RoutePassthroughComponent,
            data: { title: 'Lessons', breadcrumb: 'LESSONS', path: 'admin' },
            children: [
              {
                path: '',
                component: LessonOverviewComponent,
              },
              {
                path: 'add',
                component: LessonEditorComponent,
                data: {
                  title: 'Add',
                  breadcrumb: 'ADD LESSON',
                  path: 'admin/lessons',
                },
              },
              {
                path: ':id/edit',
                component: LessonEditorComponent,
                data: {
                  title: 'Edit',
                  breadcrumb: 'EDIT LESSON',
                  path: 'admin/lessons',
                },
              },
              {
                path: '**',
                redirectTo: 'lessons',
              },
            ],
          },
          {
            path: 'courses',
            component: RoutePassthroughComponent,
            data: { title: 'Courses', breadcrumb: 'COURSES', path: 'admin' },
            children: [
              { path: '', component: CoursesComponent },
              {
                path: 'add',
                component: CourseEditorComponent,
                data: {
                  title: 'Add',
                  breadcrumb: 'ADD COURSE',
                  path: 'admin/courses',
                },
              },
              {
                path: 'manage/:id',
                component: CourseEditorComponent,
                data: {
                  title: 'Manage',
                  breadcrumb: 'MANAGE COURSE',
                  path: 'admin/courses',
                },
              },
              {
                path: 'category',
                component: CategoryManagerComponent,
                children: [
                  {
                    path: 'course',
                    component: RoutePassthroughComponent,
                    data: {
                      title: 'Course',
                      breadcrumb: 'COURSE',
                      path: 'admin/courses/category',
                    },
                    children: [
                      {
                        path: 'add',
                        component: AddCourseCategoryComponent,
                        data: {
                          title: 'Add Category',
                          breadcrumb: 'ADD CATEGORY',
                          path: 'admin/courses/category/course',
                        },
                      },
                      {
                        path: 'manage/:id',
                        component: ManageCourseCategoryComponent,
                        data: {
                          title: 'Manage Category',
                          breadcrumb: 'MANAGE CATEGORY',
                          path: 'admin/courses/category/course',
                        },
                      },
                      {
                        path: 'overview',
                        component: CourseCategoriesOverviewComponent,
                        data: {
                          title: 'Overview',
                          breadcrumb: 'OVERVIEW',
                          path: 'admin/courses/category/course',
                        },
                      },
                      {
                        path: '**',
                        redirectTo: 'overview',
                      },
                    ],
                  },

                  {
                    path: 'task',
                    component: RoutePassthroughComponent,
                    data: {
                      title: 'Task',
                      breadcrumb: 'TASK',
                      path: 'admin/courses/category',
                    },
                    children: [
                      {
                        path: 'add',
                        component: AddTaskCategoryComponent,
                        data: {
                          title: 'Add Category',
                          breadcrumb: 'ADD CATEGORY',
                          path: 'admin/courses/category/task',
                        },
                      },
                      {
                        path: 'manage/:id',
                        component: ManageTaskCategoryComponent,
                        data: {
                          title: 'Manage Category',
                          breadcrumb: 'MANAGE CATEGORY',
                          path: 'admin/courses/category/task',
                        },
                      },
                      {
                        path: 'overview',
                        component: TaskCategoriesOverviewComponent,
                        data: {
                          title: 'Overview',
                          breadcrumb: 'OVERVIEW',
                          path: 'admin/courses/category/task',
                        },
                      },
                      {
                        path: '**',
                        redirectTo: 'overview',
                      },
                    ],
                  },
                  { path: '**', redirectTo: 'course' },
                ],
                data: {
                  title: 'Categories',
                  breadcrumb: 'CATEGORIES',
                  path: 'admin/courses',
                },
              },
              {
                path: 'aircraft',
                component: RoutePassthroughComponent,
                children: [
                  {
                    path: '',
                    component: AircraftComponent,
                    data: {
                      title: 'Aircraft',
                      breadcrumb: 'AIRCRAFT',
                      path: 'admin/courses',
                    },
                  },
                  {
                    path: 'add',
                    component: AddAircraftComponent,
                    data: {
                      title: 'Add Aircraft',
                      breadcrumb: 'ADD AIRCRAFT',
                      path: 'admin/courses/aircraft',
                    },
                  },
                  {
                    path: 'manage/:id',
                    component: ManageAircraftComponent,
                    data: {
                      title: 'Manage Aircraft',
                      breadcrumb: 'MANAGE AIRCRAFT',
                      path: 'admin/courses/aircraft',
                    },
                  },
                ],
                data: {
                  title: 'Aircraft',
                  breadcrumb: 'AIRCRAFT',
                  path: 'admin/courses',
                },
              },
              {
                path: 'avionics',
                component: RoutePassthroughComponent,
                children: [
                  {
                    path: '',
                    component: AvionicsComponent,
                    data: {
                      title: 'Avionics',
                      breadcrumb: 'AVIONICS',
                      path: 'admin/avionics',
                    },
                  },
                  {
                    path: 'add',
                    component: AddAvionicsComponent,
                    data: {
                      title: 'Add Avionics',
                      breadcrumb: 'ADD AVIONICS',
                      path: 'admin/courses/avionics',
                    },
                  },
                  {
                    path: 'manage/:id',
                    component: ManageAvionicsComponent,
                    data: {
                      title: 'Manage Avionics',
                      breadcrumb: 'MANAGE AVIONICS',
                      path: 'admin/courses/avionics',
                    },
                  },
                ],
                data: {
                  title: 'Avionics',
                  breadcrumb: 'AVIONICS',
                  path: 'admin/courses',
                },
              },
              {
                path: 'task',
                component: TaskManagerComponent,
                children: [
                  {
                    path: 'overview',
                    component: TaskManagerOverviewComponent,
                    data: {
                      title: 'Task Overview',
                      breadcrumb: 'TASK OVERVIEW',
                      path: 'admin/courses',
                    },
                  },
                  {
                    path: 'add',
                    component: AddTaskComponent,
                    data: {
                      title: 'Add Task',
                      breadcrumb: 'ADD TASK',
                      path: 'admin/courses/task',
                    },
                  },
                  {
                    path: 'manage/:id',
                    component: ManageTaskComponent,
                    data: {
                      title: 'Manage Task',
                      breadcrumb: 'MANAGE TASK',
                      path: 'admin/courses/task',
                    },
                  },
                  {
                    path: 'defer-reasons-overview',
                    component: DeferReasonOverviewComponent,
                    data: {
                      title: 'Defer Reason',
                      breadcrumb: 'DEFER REASONS',
                      path: 'admin/courses/task',
                    },
                  },
                  {
                    path: 'manage-defer-reason/:id',
                    component: ManageDeferReasonComponent,
                    data: {
                      title: 'Manage Defer Reason',
                      breadcrumb: 'MANAGE DEFER REASONS',
                      path: 'admin/courses/task',
                    },
                  },
                  {
                    path: 'add-defer-reason',
                    component: AddDeferReasonComponent,
                    data: {
                      title: 'Add Defer Reason',
                      breadcrumb: 'ADD DEFER REASONS',
                      path: 'admin/courses/task',
                    },
                  },
                  {
                    path: '**',
                    redirectTo: 'overview',
                  },
                ],
                data: {
                  title: 'Task',
                  breadcrumb: 'TASK',
                  path: 'admin/courses',
                },
              },
              {
                path: 'content',
                component: RoutePassthroughComponent,
                children: [
                  {
                    path: '',
                    component: ContentManagerOverviewComponent,
                    data: {
                      title: 'Content',
                      breadcrumb: 'CONTENT',
                      path: 'admin/courses',
                    },
                  },
                  {
                    path: 'add',
                    component: AddContentComponent,
                    data: {
                      title: 'Add Content',
                      breadcrumb: 'ADD CONTENT',
                      path: 'admin/courses/content',
                    },
                  },
                  {
                    path: 'manage/:id',
                    component: ManageContentComponent,
                    data: {
                      title: 'Manage Category',
                      breadcrumb: 'MANAGE CONTENT',
                      path: 'admin/courses/content',
                    },
                  },
                ],
                data: {
                  title: 'Content',
                  breadcrumb: 'CONTENT',
                  path: 'admin/courses',
                },
              },
              /*
              {
                path: 'quizzes', component: QuizManagerComponent,
                children: [
                  {
                    path: 'quiz-bank',
                    component: QuizzesComponent,
                    data: { title: 'Quiz Bank', breadcrumb: 'QUIZ BANK', path: 'admin/courses'}
                  },
                  {
                    path: 'quiz-builder',
                    component: CreateQuizComponent,
                    data: { title: 'Quiz Builder', breadcrumb: 'QUIZ BUILDER', path: 'admin/courses/quizzes'}
                  },
                  {
                    path: 'quiz-builder/:id',
                    component: CreateQuizComponent,
                    data: { title: 'Manage Quiz', breadcrumb: 'MANAGE QUIZ', path: 'admin/courses/quizzes'}
                  },
                  {
                    path: 'question-bank',
                    component: QuestionBankComponent,
                    data: { title: 'Question Bank', breadcrumb: 'QUESTION BANK', path: 'admin/courses/quizzes'}
                  },
                  {
                    path: 'question-builder',
                    component: CreateQuestionComponent,
                    data: { title: 'Question Builder', breadcrumb: 'QUESTION BUILDER', path: 'admin/courses/quizzes'}
                  },
                  {
                    path: 'question-builder/:id',
                    component: CreateQuestionComponent,
                    data: { title: 'Manage Question', breadcrumb: 'MANAGE QUESTION', path: 'admin/courses/quizzes'}
                  },
                  {path: '**', redirectTo: 'quiz-bank'}
                ],
                data: { title: 'Quizzes', breadcrumb: 'QUIZZES', path: 'admin/courses'}
              },
*/
              { path: '**', redirectTo: '' },
            ],
          },
          { path: 'error-page', component: ErrorPageComponent },
          { path: '**', redirectTo: 'dashboard' },
        ],
      },
    ],
    canActivate: [AdminGuard],
  },

  {
    path: '',
    component: CtcLayoutComponent,
    children: [
      {
        path: 'ctc',
        children: [
          {
            path: 'instructors',
            component: InstructorManagerComponent,
            data: {
              title: 'Instructors',
              breadcrumb: 'INSTRUCTORS',
              path: 'ctc/user-manager',
              type: 'ctc',
            },
          },
          {
            path: 'classroom',
            component: RoutePassthroughComponent,
            children: [
              {
                path: 'students',
                component: StudentManagerLayoutComponent,
                data: {
                  title: 'Students',
                  breadcrumb: 'STUDENTS',
                  path: 'ctc/classroom',
                  noRootPath: true,
                },
                children: [
                  {
                    path: '',
                    component: StudentManagerComponent,
                    data: {
                      title: 'Students',
                      breadcrumb: 'STUDENTS',
                      path: 'ctc/classroom/students',
                      type: 'ctc',
                    },
                  },
                ],
              },
              {
                path: 'transcripts/:id',
                component: TranscriptsManagerComponent,
                data: {
                  title: 'Transcripts',
                  breadcrumb: 'TRANSCRIPTS',
                  path: 'ctc/classroom/transcripts/{{id}}',
                  noRootPath: true,
                },
              },
              {
                path: 'transcripts/:user_id/course-detail/:id',
                component: TranscriptCourseDetailComponent,
                data: {
                  title: 'Course Detail',
                  breadcrumb: 'COURSE DETAIL',
                  path: 'ctc/classroom/transcripts/{{user_id}}/course-detail/{{id}}',
                  noRootPath: true,
                },
              },
              {
                path: 'assignments/student/:id',
                component: StudentAssignmentsComponent,
                data: {
                  title: 'Assignments',
                  breadcrumb: 'ASSIGNMENTS',
                  path: 'ctc/classroom/students',
                },
              },
              {
                path: 'student/:user_id',
                component: StudentUserCoursesComponent,
                data: {
                  title: 'User Courses',
                  breadcrumb: 'USER COURSES',
                  path: 'ctc/classroom/students',
                },
              },
              {
                path: 'transcripts/enrollments/:user_id/course/:course_id',
                component: EnrollmentHistoryComponent,
                data: {
                  title: 'Enrollment History',
                  breadcrumb: 'ENROLLMENT HISTORY',
                  path: 'ctc/classroom/transcripts/enrollments/{{user_id}}/course/{{id}}',
                  noRootPath: true,
                },
              },
              {
                path: 'student/:user_id/course/:course_id/course_attempt/:course_attempt_id/assessments',
                component: StudentAssessmentsLayoutComponent,
                data: {
                  title: 'Assessments',
                  breadcrumb: 'ASSESSMENTS',
                  path: 'ctc/classroom/student/{{user_id}}/course/{{course_id}}/course_attempt/{{course_attempt_id}}/assessments',
                  noRootPath: true,
                },
                children: [
                  {
                    path: '',
                    component: StudentAssessmentsComponent,
                    data: {
                      title: 'Assessments',
                      breadcrumb: 'ASSESSMENTS',
                      path: 'ctc/classroom/student/{{user_id}}/course/{{course_id}}/course_attempt/{{course_attempt_id}}',
                      noRootPath: true,
                    },
                  },
                  {
                    path: 'assessment-entry/:assessment_id',
                    component: StudentAssessmentsEntryComponent,
                    data: {
                      title: 'Assessment Entry',
                      breadcrumb: 'ASSESSMENT ENTRY',
                      path: 'ctc/classroom/student/{{user_id}}/course/{{course_id}}/course_attempt/{{course_attempt_id}}/assessments/assessment-entry/{{assessment_id}}',
                      noRootPath: true,
                    },
                  },
                  {
                    path: 'task-detail/:task_id',
                    component: StudentAssessmentsTaskDetailComponent,
                    data: {
                      title: 'Task Detail',
                      breadcrumb: 'TASK DETAIL',
                      path: 'ctc/classroom/student/{{user_id}}/course/{{course_id}}/assessments/task-detail/{{task_id}}',
                      noRootPath: true,
                    },
                  },
                  {
                    path: 'assessment-entries/:assessment_id',
                    component: StudentAssessmentsViewComponent,
                    data: {
                      title: 'Assessment Entries',
                      breadcrumb: 'ASSESSMENT ENTRIES',
                      path: 'ctc/classroom/students/assessments/:id',
                    },
                  },
                  {
                    path: '**',
                    redirectTo: '',
                  },
                ],
              },
              {
                path: 'assignments',
                component: AssignmentsManagerLayoutComponent,
                data: {
                  title: 'Assignments',
                  breadcrumb: 'ASSIGNMENTS',
                  path: 'instructor/classroom',
                },
                children: [
                  {
                    path: '',
                    component: AssignmentsManagerOverviewComponent,
                    data: {
                      title: 'Overview',
                      breadcrumb: 'OVERVIEW',
                      path: 'instructor/classroom/assignments',
                    },
                  },
                  {
                    path: 'add',
                    component: AssignmentsManagerAddComponent,
                    data: {
                      title: 'Add Assignment',
                      breadcrumb: 'ADD ASSIGNMENT',
                      path: 'instructor/classroom/assignments/add',
                    },
                  },
                  {
                    path: 'manage/:id',
                    component: AssignmentsManagerManageComponent,
                    data: {
                      title: 'Manage Assignment',
                      breadcrumb: 'MANAGE ASSIGNMENT',
                      path: 'instructor/classroom/assignments/manage',
                    },
                  },
                  { path: '**', redirectTo: '' },
                ],
              },
              { path: '**', redirectTo: '' },
            ],
            data: {
              title: 'Dashboard',
              breadcrumb: 'DASHBOARD',
              path: 'ctc/dashboard',
            },
          },
          {
            path: 'dashboard',
            component: CtcDashboardComponent,
            data: {
              title: 'Dashboard',
              breadcrumb: 'DASHBOARD',
              path: 'ctc/dashboard',
            },
          },
          {
            path: 'student-dashboard',
            component: ClassroomDashboardComponent,
            data: {
              title: 'Student Dashboard',
              breadcrumb: 'STUDENT DASHBOARD',
              path: 'instructor/student-dashboard',
            },
          },
          // {
          //   path: 'notifications', component: NotificationsInstructorManagerComponent,
          //   data: { title: 'Notifications', breadcrumb: 'NOTIFICATIONS', path: 'instructor/notifications'}
          // },
          { path: 'error-page', component: ErrorPageComponent },
          { path: '**', redirectTo: 'dashboard' },
        ],
      },
    ],
    canActivate: [CTCGuard],
  },

  {
    path: '',
    component: InstructorLayoutComponent,
    children: [
      {
        path: 'instructor',
        children: [
          {
            path: 'bookshelf',
            component: RoutePassthroughComponent,
            data: {
              title: 'Bookshelf',
              breadcrumb: 'BOOKSHELF',
              path: 'instructor/bookshelf',
            },
            children: [
              {
                path: '',
                component: BookshelfManagerOverviewComponent,
                data: {
                  title: 'Overview',
                  breadcrumb: 'OVERVIEW',
                  path: 'instructor/bookshelf',
                },
              },
              {
                path: 'add',
                component: BookshelfAddContentComponent,
                data: {
                  title: 'Add',
                  breadcrumb: 'ADD',
                  path: 'instructor/bookshelf/add',
                },
              },
              {
                path: 'manage/:id',
                component: BookshelfManageContentComponent,
                data: {
                  title: 'Manage',
                  breadcrumb: 'MANAGE',
                  path: 'instructor/bookshelf/manage',
                },
              },
              {
                path: '**',
                redirectTo: '',
              },
            ],
          },
          {
            path: 'classroom',
            component: RoutePassthroughComponent,
            children: [
              {
                path: 'students',
                component: StudentManagerLayoutComponent,
                data: {
                  title: 'Students',
                  breadcrumb: 'STUDENTS',
                  path: 'instructor/classroom',
                  noRootPath: true,
                },
                children: [
                  {
                    path: '',
                    component: StudentManagerComponent,
                    data: {
                      title: 'Students',
                      breadcrumb: 'STUDENTS',
                      path: 'instructor/classroom/students',
                    },
                  },
                ],
              },
              {
                path: 'transcripts/:id',
                component: TranscriptsManagerComponent,
                data: {
                  title: 'Transcripts',
                  breadcrumb: 'TRANSCRIPTS',
                  path: 'instructor/classroom/transcripts/{{id}}',
                  noRootPath: true,
                },
              },
              {
                path: 'transcripts/:user_id/course-detail/:id',
                component: TranscriptCourseDetailComponent,
                data: {
                  title: 'Course Detail',
                  breadcrumb: 'COURSE DETAIL',
                  path: 'instructor/classroom/transcripts/{{user_id}}/course-detail/{{id}}',
                  noRootPath: true,
                },
              },
              {
                path: 'assignments/student/:id',
                component: StudentAssignmentsComponent,
                data: {
                  title: 'Assignments',
                  breadcrumb: 'ASSIGNMENTS',
                  path: 'instructor/classroom/students',
                },
              },
              {
                path: 'student/:user_id',
                component: StudentUserCoursesComponent,
                data: {
                  title: 'User Courses',
                  breadcrumb: 'USER COURSES',
                  path: 'instructor/classroom/students',
                },
              },
              {
                path: 'transcripts/enrollments/:user_id/course/:course_id',
                component: EnrollmentHistoryComponent,
                data: {
                  title: 'Enrollment History',
                  breadcrumb: 'ENROLLMENT HISTORY',
                  path: 'instructor/classroom/transcripts/enrollments/{{user_id}}/course/{{id}}',
                  noRootPath: true,
                },
              },
              {
                path: 'student/:user_id/course/:course_id/course_attempt/:course_attempt_id/assessments',
                component: StudentAssessmentsLayoutComponent,
                data: {
                  title: 'Assessments',
                  breadcrumb: 'ASSESSMENTS',
                  path: 'instructor/classroom/student/{{user_id}}/course/{{course_id}}/course_attempt/{{course_attempt_id}}/assessments',
                  noRootPath: true,
                },
                children: [
                  {
                    path: '',
                    component: StudentAssessmentsComponent,
                    data: {
                      title: 'Assessments',
                      breadcrumb: 'ASSESSMENTS',
                      path: 'instructor/classroom/student/{{user_id}}/course/{{course_id}}/course_attempt/{{course_attempt_id}}',
                      noRootPath: true,
                    },
                  },
                  {
                    path: 'assessment-entry/:assessment_id',
                    component: StudentAssessmentsEntryComponent,
                    data: {
                      title: 'Assessment Entry',
                      breadcrumb: 'ASSESSMENT ENTRY',
                      path: 'instructor/classroom/student/{{user_id}}/course/{{course_id}}/course_attempt/{{course_attempt_id}}/assessments/assessment-entry/{{assessment_id}}',
                      noRootPath: true,
                    },
                  },
                  {
                    path: 'task-detail/:task_id',
                    component: StudentAssessmentsTaskDetailComponent,
                    data: {
                      title: 'Task Detail',
                      breadcrumb: 'TASK DETAIL',
                      path: 'instructor/classroom/student/{{user_id}}/course/{{course_id}}/assessments/task-detail/{{task_id}}',
                      noRootPath: true,
                    },
                  },
                  {
                    path: 'assessment-entries/:assessment_id',
                    component: StudentAssessmentsViewComponent,
                    data: {
                      title: 'Assessment Entries',
                      breadcrumb: 'ASSESSMENT ENTRIES',
                      path: 'instructor/classroom/students/assessments/:id',
                    },
                  },
                  {
                    path: '**',
                    redirectTo: '',
                  },
                ],
              },
              {
                path: 'assignments',
                component: AssignmentsManagerLayoutComponent,
                data: {
                  title: 'Assignments',
                  breadcrumb: 'ASSIGNMENTS',
                  path: 'instructor/classroom',
                },
                children: [
                  {
                    path: '',
                    component: AssignmentsManagerOverviewComponent,
                    data: {
                      title: 'Overview',
                      breadcrumb: 'OVERVIEW',
                      path: 'instructor/classroom/assignments',
                    },
                  },
                  {
                    path: 'add',
                    component: AssignmentsManagerAddComponent,
                    data: {
                      title: 'Add Assignment',
                      breadcrumb: 'ADD ASSIGNMENT',
                      path: 'instructor/classroom/assignments/add',
                    },
                  },
                  {
                    path: 'manage/:id',
                    component: AssignmentsManagerManageComponent,
                    data: {
                      title: 'Manage Assignment',
                      breadcrumb: 'MANAGE ASSIGNMENT',
                      path: 'instructor/classroom/assignments/manage',
                    },
                  },
                  { path: '**', redirectTo: '' },
                ],
              },
              { path: '**', redirectTo: '' },
            ],
            data: {
              title: 'Dashboard',
              breadcrumb: 'DASHBOARD',
              path: 'instructor/dashboard',
            },
          },
          {
            path: 'dashboard',
            component: InstructorDashboardComponent,
            data: {
              title: 'Dashboard',
              breadcrumb: 'DASHBOARD',
              path: 'instructor/dashboard',
            },
          },
          {
            path: 'student-dashboard',
            component: ClassroomDashboardComponent,
            data: {
              title: 'Student Dashboard',
              breadcrumb: 'STUDENT DASHBOARD',
              path: 'instructor/student-dashboard',
            },
          },
          // {
          //   path: 'notifications', component: NotificationsInstructorManagerComponent,
          //   data: { title: 'Notifications', breadcrumb: 'NOTIFICATIONS', path: 'instructor/notifications'}
          // },
          { path: 'error-page', component: ErrorPageComponent },
          { path: '**', redirectTo: 'dashboard' },
        ],
      },
    ],
    canActivate: [InstructorGuard],
  },
  {
    path: '',
    component: UserLayoutComponent,
    children: [
      {
        path: 'home',
        component: MyLearningComponent,
        data: {
          title: 'My Learning',
          breadcrumb: 'COURSES',
          path: '',
          showNavbar: true,
        },
      },
      {
        path: 'library',
        component: ContentLibraryComponent,
        data: {
          title: 'Library',
          breadcrumb: 'COURSES',
          path: '',
          showNavbar: true,
          canActivate: [UserGuard],
        },
      },
      {
        path: 'instructors',
        component: InstructorsComponent,
        data: {
          title: 'Instructors',
          breadcrumb: 'COURSES',
          path: '',
          showNavbar: true,
          canActivate: [UserGuard],
        },
      },
      {
        path: 'my-courses',
        component: MyCoursesComponent,
        data: {
          title: 'My Courses',
          breadcrumb: 'COURSES',
          path: '',
          showNavbar: true,
        },
        canActivate: [UserGuard],
      },
      {
        path: 'achievements',
        component: BadgesCertsComponent,
        data: {
          title: 'Achievements',
          breadcrumb: 'COURSES',
          path: '',
          showNavbar: true,
        },
        children: [
          {
            path: 'certificates',
            component: CertificatesComponent,
            data: { showNavbar: true },
          },
          {
            path: 'badges',
            component: BadgesComponent,
            data: { showNavbar: true },
          },
          { path: '**', redirectTo: 'certificates' },
        ],
        canActivate: [UserGuard],
      },
      {
        path: 'learning-catalog',
        component: CourseCatalogComponent,
        data: {
          title: 'Learning Catalog',
          breadcrumb: 'COURSES',
          showNavbar: true,
        },
      },
      {
        path: 'help-center',
        component: HelpComponent,
        data: { title: 'Help Center', breadcrumb: 'COURSES', showNavbar: true },
      },
      {
        path: 'purchase-history',
        component: PurchaseHistoryComponent,
        data: { title: 'Purchase History', breadcrumb: 'Purchase History' },
      },
      {
        path: 'shopping-cart',
        component: UserShoppingCartComponent,
        data: { title: 'Shopping Cart', breadcrumb: 'Shopping Cart' },
      },
      {
        path: 'checkout',
        component: CheckoutComponent,
        data: { title: 'Checkout', breadcrumb: 'Checkout' },
      },
      {
        path: 'recent-purchase/:id',
        component: RecentPurchasesComponent,
        data: { title: 'Recent Purchases', breadcrumb: '' },
      },
      {
        path: 'receipt/:id',
        component: ReceiptComponent,
        data: { title: 'Receipt', breadcrumb: 'Receipt' },
      },
      {
        path: 'course',
        component: RetiredRouteRedirectComponent,
        children: [
          { path: 'overview', component: RetiredRouteRedirectComponent },
          { path: 'achievements', component: RetiredRouteRedirectComponent },
          { path: 'workbook', component: RetiredRouteRedirectComponent },
          { path: 'user-library', component: RetiredRouteRedirectComponent },
          { path: '**', component: RetiredRouteRedirectComponent },
        ],
        canActivate: [UserGuard],
      },
      {
        path: 'authorized-training-network',
        component: TrainingPartnersComponent,
        data: {
          title: 'Authorized Training Network',
          breadcrumb: 'Authorized Training Network',
          showNavbar: true,
        },
        canActivate: [UserGuard, TrainingCentersGuard],
      },
      { path: 'error-page', component: ErrorPageComponent },
      // { path: '**', redirectTo: 'home' }
    ],
  },
  { path: '**', redirectTo: 'learning-catalog' },
];

@NgModule({
  providers: [
    {
      provide: coursesUrlProvider,
      useValue: (route: ActivatedRouteSnapshot) => {
        window.open(getResolvedUrl(route), '_self');
      },
    },
  ],
  imports: [RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
